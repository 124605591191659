import Lesson1 from "./lesson-1.json"
import Lesson2 from "./lesson-2.json"
import Lesson3 from "./lesson-3.json"
import Lesson4 from "./lesson-4.json"
import Lesson5 from "./lesson-5.json"
import Lesson6 from "./lesson-6.json"
import Lesson7 from "./lesson-7.json"
import Lesson8 from "./lesson-8.json"
import Lesson9 from "./lesson-9.json"
import Lesson10 from "./lesson-10.json"
import Lesson11 from "./lesson-11.json"
import Lesson12 from "./lesson-12.json"
import Lesson13 from "./lesson-13.json"
import Lesson14 from "./lesson-14.json"
import Lesson15 from "./lesson-15.json"
import Lesson16 from "./lesson-16.json"
import Lesson17 from "./lesson-17.json"
import Lesson18 from "./lesson-18.json"
import Lesson19 from "./lesson-19.json"
import Lesson20 from "./lesson-20.json"
import Lesson21 from "./lesson-21.json"
import Lesson22 from "./lesson-22.json"
import Lesson23 from "./lesson-23.json"
import Lesson24 from "./lesson-24.json"
import Lesson25 from "./lesson-25.json"
import Lesson26 from "./lesson-26.json"
import Lesson27 from "./lesson-27.json"
import Lesson28 from "./lesson-28.json"
import Lesson29 from "./lesson-29.json"
import Lesson30 from "./lesson-30.json"
import Lesson31 from "./lesson-31.json"
import Lesson32 from "./lesson-32.json"
import Lesson33 from "./lesson-33.json"
import Lesson34 from "./lesson-34.json"
import Lesson35 from "./lesson-35.json"
import Lesson36 from "./lesson-36.json"
import Lesson37 from "./lesson-37.json"
import Lesson38 from "./lesson-38.json"
import Lesson39 from "./lesson-39.json"
import Lesson40 from "./lesson-40.json"
import Lesson41 from "./lesson-41.json"
import Lesson42 from "./lesson-42.json"
import Lesson43 from "./lesson-43.json"
import Lesson44 from "./lesson-44.json"
import Lesson45 from "./lesson-45.json"
import Lesson46 from "./lesson-46.json"
import Lesson47 from "./lesson-47.json"
import Lesson48 from "./lesson-48.json"
import Lesson49 from "./lesson-49.json"
import Lesson50 from "./lesson-50.json"
import Lesson51 from "./lesson-51.json"
import Lesson52 from "./lesson-52.json"
import Lesson53 from "./lesson-53.json"
import Lesson54 from "./lesson-54.json"
import Lesson55 from "./lesson-55.json"
export const g3 = {
    "audioPrefix": "g3ct",
    days: [
        ...Lesson1,
        ...Lesson2,
        ...Lesson3,
        ...Lesson4,
        ...Lesson5,
        ...Lesson6,
        ...Lesson7,
        ...Lesson8,
        ...Lesson9,
        ...Lesson10,
        ...Lesson11,
        ...Lesson12,
        ...Lesson13,
        ...Lesson14,
        ...Lesson15,
        ...Lesson16,
        ...Lesson17,
        ...Lesson18,
        ...Lesson19,
        ...Lesson20,
        ...Lesson21,
        ...Lesson22,
        ...Lesson23,
        ...Lesson24,
        ...Lesson25,
        ...Lesson26,
        ...Lesson27,
        ...Lesson28,
        ...Lesson29,
        ...Lesson30,
        ...Lesson31,
        ...Lesson32,
        ...Lesson33,
        ...Lesson34,
        ...Lesson35,
        ...Lesson36,
        ...Lesson37,
        ...Lesson38,
        ...Lesson39,
        ...Lesson40,
        ...Lesson41,
        ...Lesson42,
        ...Lesson43,
        ...Lesson44,
        ...Lesson45,
        ...Lesson46,
        ...Lesson47,
        ...Lesson48,
        ...Lesson49,
        ...Lesson50,
        ...Lesson51,
        ...Lesson52,
        ...Lesson53,
        ...Lesson54,
        ...Lesson55
    ].map(lesson => {
        if (lesson.type === "day3") {
            lesson.paragraph.breaks = []
        }
        return lesson
    })
}